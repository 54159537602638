<!--
 * @Description: 工厂发货---》拣货弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-02 20:33:56
 * @LastEditTime: 2023-03-21 10:18:38
 * @LastEditors: ChenXueLin
-->
<template>
  <div :class="[pickVisible ? 'dialogMask' : 'hiddenMask']">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="pickVisible"
      :close-on-click-modal="false"
      width="1080px"
      v-loading="editDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="custom-dialog"
      :before-close="closeDialog"
      @open="openDialog"
      append-to-body
    >
      <div class="btn-box">
        <el-button
          type="text"
          v-clipboard:copy="copyValue"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          >一键复制</el-button
        >
      </div>
      <el-form label-width="88px" :model="baseInfo" class="detailForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="客户名称:">
              {{ baseInfo.customerName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收件人:">
              {{ baseInfo.receiverUserName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话:">
              {{ baseInfo.phoneNo }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="地址:">
              {{ baseInfo.address }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="dialogType == 2 || dialogType == 3">
            <el-form-item label="销售:">
              {{ baseInfo.saleUserName }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="dialogType == 2 || dialogType == 3">
            <el-form-item label="订单标签:">
              {{ baseInfo.orderLabelNameList.join(",") }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="dialogType == 2 || dialogType == 3">
            <el-form-item label=" 订单备注:">
              {{ baseInfo.orderRemarkList.join(",") }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form
        label-width="88px"
        ref="express"
        :model="express"
        :rules="deliveryType == 1 ? {} : expressRules"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item prop="deliveryType">
              <el-checkbox
                v-model="deliveryType"
                :true-label="1"
                :false-label="2"
                @change="changeDeliveryType"
                :disabled="disabledDeliveryType"
                >自提</el-checkbox
              >
              <span>(</span>
              <span style="color:red;">*</span>
              <span>无实际物流时,请勾选自提 )</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="快递公司" prop="expressCompanyType">
              <e6-vr-select
                :data="expressCompanyList"
                placeholder="请选择快递公司名称"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                v-model="express.expressCompanyType"
                clearable
                @change="selectExpress"
                :disabled="deliveryType == 1"
              ></e6-vr-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="快递单号" prop="expressNo">
              <el-input
                v-model.trim="express.expressNo"
                placeholder="请输入快递单号"
                :disabled="deliveryType == 1"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="16">
            <el-form-item label="发货备注" prop="deliverRemark">
              <el-input
                v-model="express.deliverRemark"
                placeholder="请输入发货备注"
                type="textarea"
                :rows="2"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="快递包裹数" prop="packageCount">
              <el-input
                style="width:130px;"
                v-model.trim="express.packageCount"
                placeholder=">0的整数"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        border
        :data="tableData"
        highlight-current-row
        :height="200 + 'px'"
      >
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.$index + 1 }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="column.fieldName === 'expressCompanyType'">
              <e6-vr-select
                v-model="row.expressCompanyType"
                :data="expressCompanyList"
                placeholder="快递公司"
                title="快递公司"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                @change="
                  (val, node) => {
                    changeExpress(val, node, row);
                  }
                "
              ></e6-vr-select>
            </div>
            <div v-else-if="column.fieldName === 'expressNo'">
              <el-input
                v-model="row.expressNo"
                placeholder="快递编号"
                title="快递编号"
                @input="
                  val => {
                    handleRowInput(val, row);
                  }
                "
              ></el-input>
            </div>
            <!-- 拣货数量 -->
            <div v-else-if="column.fieldName === 'pickNum'">
              <el-input
                v-if="!row.batchManage"
                v-model="row.pickNum"
                placeholder="数量"
                title="数量"
                @input="
                  val => {
                    inputNumber(val, row);
                  }
                "
              ></el-input>
              <span v-else>{{ row.equipNoList.length }}</span>
            </div>
            <!-- 剩余未发 -->
            <div v-else-if="column.fieldName === 'surplusNum'">
              <span>{{
                row.batchManage
                  ? row.leftAmount - row.equipNoList.length
                  : row.leftAmount - Number(row.pickNum)
              }}</span>
            </div>
            <!-- 启用批次号才需要填写设备号 -->
            <span v-else-if="column.fieldName === 'equipNoList'">
              <span
                @click="handleScan(row)"
                style="color:#46BFEA;cursor: pointer;"
                v-if="row.batchManage"
              >
                {{
                  row.equipNoList.length
                    ? row.equipNoList.join(",")
                    : "扫描或输入"
                }}
              </span>
            </span>
            <!-- 启用批次号才需要填写 -->
            <span
              v-else-if="
                column.fieldName === 'simNoListStr' && row.enableEditSimInfo
              "
            >
              <el-input
                placeholder="SIM卡号"
                v-model="row.simNoListStr"
              ></el-input>
            </span>
            <span
              v-else-if="
                column.fieldName === 'centerIdCodeListStr' &&
                  row.enableEditSimInfo
              "
            >
              <el-input
                placeholder="中心识别码"
                v-model="row.centerIdCodeListStr"
              ></el-input>
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 扫描输入框 -->
    <scan-input
      :inputVisible.sync="inputVisible"
      :maxLength="clickRow.leftAmount"
      :defaultValue="clickRow.equipNoList"
      @confirm="confirmInput"
    ></scan-input>
  </div>
</template>

<script>
import base from "@/mixins/base";
import {
  batchOutWarehouseConfirm,
  getApplyDetail,
  findDownList,
  outWarehouseConfirm
} from "@/api";
// import { positiveIntegerValid } from "@/utils/validate";
import { printError } from "@/utils/util";
import ScanInput from "./scanInput/scanInput.vue";
export default {
  name: "pickProductDialog",
  components: { ScanInput },
  data() {
    return {
      editDialogLoading: false,
      inputVisible: false,
      enableEditSimInfo: false,
      materialIsEmpty: false,
      expressCompanyList: [], //快递公司
      baseInfo: {
        orderLabelNameList: [],
        orderRemarkList: []
      },
      deliveryType: 2, //是否自提1自提2快递
      disabledDeliveryType: false,
      express: {
        expressNo: "", //快递单号
        expressCompanyType: "", //快递公司类型
        expressCompanyName: "", //快递公司名称
        packageCount: "", //包裹数
        deliverRemark: ""
      },
      expressRules: {
        expressCompanyType: [
          {
            required: true,
            message: "请选择快递公司",
            trigger: ["blur", "change"]
          }
        ],
        expressNo: [
          {
            required: true,
            message: "请输入快递单号",
            trigger: ["blur", "change"]
          }
        ]
        // packageCount: positiveIntegerValid.required({
        //   requiredMsg: "请输入快递包裹数",
        //   message: "只能为>0的整数",
        //   trigger: ["blur", "change"]
        // })
      },
      columnData1: [
        {
          fieldName: "materialName",
          display: true,
          fieldLabel: "物料名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "物料编码",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "amount",
          display: true,
          fieldLabel: "申请数量",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "warehouseName",
          display: true,
          fieldLabel: "仓库",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "stockAmount",
          display: true,
          fieldLabel: "仓库余量",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "pickNum",
          display: true,
          fieldLabel: "本次已拣货",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipNoList",
          display: true,
          fieldLabel: "拣货明细",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "expressCompanyType",
          display: true,
          fieldLabel: "快递公司",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "expressNo",
          display: true,
          fieldLabel: "快递单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //工厂拣货的表头
      columnData2: [
        {
          fieldName: "materialName",
          display: true,
          fieldLabel: "物料名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "物料编码",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simRequire",
          display: true,
          fieldLabel: "SIM卡要求",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "amount",
          display: true,
          fieldLabel: "申请数量",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "warehouseName",
          display: true,
          fieldLabel: "仓库",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "stockAmount",
          display: true,
          fieldLabel: "仓库余量",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "leftAmount",
          display: true,
          fieldLabel: "剩余待发",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "pickNum",
          display: true,
          fieldLabel: "本次已拣货",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipNoList",
          display: true,
          fieldLabel: "拣货明细",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "surplusNum",
          display: true,
          fieldLabel: "剩余未发",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "centerIdCodeListStr",
          display: true,
          fieldLabel: "中心识别码",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simNoListStr",
          display: true,
          fieldLabel: "SIM卡号",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "expressCompanyType",
          display: true,
          fieldLabel: "快递公司",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "expressNo",
          display: true,
          fieldLabel: "快递单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //分拣出库的表头
      tableData: [],
      clickRow: {}, //当前要输入设备号的行
      onlyPick: false, //是否只拣货不出库	onlyPick只有工厂时传true，其他传false
      copyValue: ""
    };
  },
  props: {
    // 弹框标题
    dialogTitle: {
      type: String,
      default: "批量拣货出库"
    },
    // 弹框是否显示
    pickVisible: {
      type: Boolean,
      default: false
    },
    //弹框类型1:调拨申请的发货出库弹框2:分拣出库的拣货出库弹框3:工厂发货的拣货弹框
    dialogType: {
      type: String,
      default: "1"
    },
    //获取申请单id集合
    applyIdList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [base],
  computed: {
    columnData() {
      return this.dialogType == 3 ? this.columnData1 : this.columnData2;
    }
  },
  created() {},
  mounted() {},
  watch: {
    pickVisible: {
      immediate: true,
      handler(value) {
        if (value) {
          this.initData();
          this.onlyPick = this.dialogType == 3 ? true : false;
        }
      }
    },
    deliveryType: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.$refs.express && this.$refs.express.clearValidate();
          });
        }
      }
    }
  },
  methods: {
    openDialog() {
      this.$nextTick(() => {
        this.$refs.express && this.$refs.express.clearValidate();
      });
    },
    //点击是否自提
    changeDeliveryType(val) {
      if (val == 1) {
        this.express.expressCompanyType = "";
        this.express.expressNo = "";
      }
    },
    async initData() {
      this.editDialogLoading = true;
      try {
        let promiseList = [
          findDownList(["supplierExpressCompany"]),
          getApplyDetail({
            applyIdList: this.applyIdList.map(item => item.applyId),
            isFactory: false
          })
        ];
        let [expressRes, detailRes] = await Promise.all(promiseList);
        //快递公司下拉框
        this.expressCompanyList = this.getFreezeResponse(expressRes, {
          path: "data.supplierExpressCompany"
        });

        let baseInfo = detailRes.data.baseInfo;
        this.baseInfo = baseInfo;
        this.enableEditSimInfo = detailRes.data.enableEditSimInfo;
        this.materialIsEmpty = detailRes.data.materialIsEmpty;
        this.copyValue =
          baseInfo.receiverUserName +
          "     " +
          baseInfo.phoneNo +
          "     " +
          baseInfo.address +
          "     " +
          baseInfo.customerName;
        this.deliveryType = baseInfo.deliveryType;
        this.disabledDeliveryType = baseInfo.deliveryType == 1 ? true : false;
        let tableData = detailRes.data.details;
        tableData.map(item => {
          item.simNoListStr = item.simNoList.join(",");
          item.centerIdCodeListStr = item.centerIdCodeList.join(",");
          item.expressNo = "";
          item.expressCompanyType = "";
          item.equipNoList = [];
          item.express = {};
          item.pickNum = item.batchManage ? "" : item.leftAmount;
          //剩余数量(代发数量-已拣货数量)
          // item.surplusNum = item.leftAmount - item.pickNum;
        });
        this.tableData = tableData;
        if (this.materialIsEmpty) {
          this.$message.warning("请先选择物料后再进行拣货出库");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //输入拣货数
    inputNumber(value, row) {
      let newVal = value.replace(/^0|[^0-9]/g, "");
      row.pickNum = newVal > row.leftAmount ? row.leftAmount : newVal;
    },
    //选择快递公司
    selectExpress(val, node) {
      this.express.expressCompanyName = node.codeName;
    },
    //选择快递公司
    changeExpress(val, node, row) {
      row.express.expressCompanyType = node.codeValue;
      row.express.expressCompanyName = node.codeName;
    },
    //输入快递单号
    handleRowInput(val, row) {
      row.express.expressNo = row.expressNo;
    },
    //点击扫描设备号
    handleScan(row) {
      this.inputVisible = true;
      this.clickRow = row;
    },
    //输入完成
    confirmInput(data) {
      this.clickRow.equipNoList = data;
    },
    onCopy() {
      this.$message({
        message: "复制成功",
        type: "success"
      });
    },

    onError() {
      this.$message.error("复制失败");
    },
    //点击确定添加
    handleSubmit() {
      if (this.materialIsEmpty) {
        this.$message.warning("请先选择物料后再进行拣货出库");
        return;
      }
      this.$refs.express.validate(valid => {
        if (valid) {
          if (this.applyIdList.length == 1) {
            //单个出库
            this.outWarehouseConfirmReq();
          } else {
            //批量出库
            this.batchOutWarehouseConfirmReq();
          }
        }
      });
    },
    //点击确定单个出库
    async outWarehouseConfirmReq() {
      try {
        this.editDialogLoading = true;
        //出库数量
        let res = await outWarehouseConfirm({
          ...this.applyIdList[0],
          deliveryType: this.deliveryType,
          express:
            this.deliveryType == 1
              ? {
                  expressNo: "", //快递单号
                  expressCompanyType: "", //快递公司类型
                  expressCompanyName: "", //快递公司名称
                  packageCount: "", //包裹数
                  deliverRemark: ""
                }
              : this.express,
          details: this.handleData(),
          onlyPick: this.onlyPick
        });
        if (res.code == "OK") {
          this.$message.success("出库成功");
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    handleData() {
      let tableData = _.cloneDeep(this.tableData);
      let details = [];
      tableData.map(item => {
        let obj = {
          materialNo: item.materialNo,
          warehouseNo: item.warehouseNo,
          warehouseOrgNo: item.warehouseOrgNo,
          outAmount: item.batchManage ? item.equipNoList.length : item.pickNum, //出库数量
          express: item.express.expressCompanyType ? item.express : null,
          equipNoList: item.equipNoList,
          centerIdCodeList: item.centerIdCodeListStr
            ? item.centerIdCodeListStr
                .split(/\n|,|，|；|;| /g)
                .filter(item => item)
            : [],
          simNoList: item.simNoListStr
            ? item.simNoListStr.split(/\n|,|，|；|;| /g).filter(item => item)
            : [],
          applyDetailId: item.applyDetailId,
          inEquipNo: item.inEquipNo
        };
        if (item.equipNoList.length || item.pickNum) {
          details.push(obj);
        }
      });
      return details;
    },
    //点击确定批量出库
    async batchOutWarehouseConfirmReq() {
      try {
        this.editDialogLoading = true;
        let res = await batchOutWarehouseConfirm({
          applyIdList: this.applyIdList,
          deliveryType: this.deliveryType,
          express:
            this.deliveryType == 1
              ? {
                  expressNo: "", //快递单号
                  expressCompanyType: "", //快递公司类型
                  expressCompanyName: "", //快递公司名称
                  packageCount: "", //包裹数
                  deliverRemark: ""
                }
              : this.express,
          details: this.handleData(),
          onlyPick: this.onlyPick
        });
        if (res.code == "OK") {
          this.$message.success("出库成功");
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //关闭弹框
    closeDialog() {
      this.baseInfo = {
        orderLabelNameList: [],
        orderRemarkList: []
      };
      this.express = {
        expressNo: "", //快递单号
        expressCompanyType: "", //快递公司类型
        expressCompanyName: "", //快递公司名称
        packageCount: "", //包裹数
        deliverRemark: ""
      };
      this.$refs.express.resetFields();
      this.$emit("update:pickVisible", false);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding-left: 10px;
    padding-right: 10px;
    .btn-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>
